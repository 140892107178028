import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  NumberField,
  ShowButton,
  TextField,
} from "react-admin";

export default () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="countryName" />
      <TextField source="countryCode" />
      <BooleanField source="isBanned" label="Banned" />
      <NumberField source="risk" label="Risk level" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
