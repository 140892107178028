import {
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from "react-admin";

type ClipListProps = Omit<ListProps, "children">;

export default (props: ClipListProps) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="createdAt" />
      <TextField source="fee" />
      <TextField source="volume" />
      <TextField source="signature" />
      <ReferenceField source="ownerId" reference="profiles" label="Creator">
        <TextField source="username" />
      </ReferenceField>
    </Datagrid>
  </List>
);
