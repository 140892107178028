import {
        NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useListParams,
  useNotify,
  useRedirect,
} from "react-admin";
import { dataProvider } from "../../providers/dataProvider";
import * as Sentry from "@sentry/react";

export default () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const urlParams = new URLSearchParams(window.location.search);
  const ownerId = urlParams.get("ownerId");

  const onSubmit = async (data: any) => {
    console.log(data);
    try {
      const result = await dataProvider.createMany("invite-codes", {
        data: {
          ownerId,
          count: data.count,
        },
      });
      console.log({result});
      //if (result.data.code ==

      notify("Invite codes created successfully", { type: "success" });
      //redirect(`/profiles/${ownerId}/show`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      notify(`Error: Invite code creation failed - ${error}`, {
        type: "error",
      });
    }
  };

  return (
    <div>
      <SimpleForm onSubmit={onSubmit}>
        <ReferenceInput
          isRequired
          source="ownerId"
          reference="profiles"
          label="Owner"
          perPage={1000}
          sort={{ field: "username", order: "ASC" }}
        >
          <SelectInput
            defaultValue={ownerId}
            optionText="username"
            disabled={true}
          />
        </ReferenceInput>
        <NumberInput source="count" />
      </SimpleForm>
    </div>
  );
};
