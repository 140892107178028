import {
  Button,
  FunctionField,
  ReferenceField,
  Show,
  ShowActions,
  SimpleShowLayout,
  TextField,
  useNotify,
  useShowController,
} from "react-admin";
import RefreshIcon from "@mui/icons-material/RefreshSharp";
import { dataProvider } from "../../providers/dataProvider";
import * as Sentry from "@sentry/react";

const ReferrerField = ({ level }: { level: number }) => {
  return (
    <>
      <small>
        Level {level} {"  "}
      </small>
      <ReferenceField
        source={`level${level}`}
        reference="profiles"
        label={`Level ${level}`}
        emptyText="none"
      >
        <TextField source="id" mr={2} />
        <TextField source="username" />
      </ReferenceField>
    </>
  );
};

const ToolbarActions = ({ swapId, refetch }: { swapId: string, refetch: () => Promise<void> }) => {
  const notify = useNotify();

  const recalculateSwap = async () => {
    console.log("recalculate one:", swapId);
    try {
      notify("Recalculating", { type: "success" });

      await dataProvider.recalculateOne("swaps", {
        data: { id: swapId },
      });

      await refetch();

      notify("Recalculating done", { type: "success" });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      notify(`Error: Recalculating swap failed - ${error}`, {
        type: "error",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "0.25rem",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button
        style={{ marginTop: "0.25rem" }}
        onClick={recalculateSwap}
        variant="text"
        color="warning"
        label="Recalculate"
        startIcon={<RefreshIcon />}
      />
      <ShowActions />
    </div>
  );
};

export default () => {
  const { record, refetch } = useShowController();

  return (
    <>
      <Show
        title={
          record?.signature
            ? `Swap ${record.signature} details`
            : "Swap details"
        }
        actions={<ToolbarActions swapId={record.id} refetch={refetch} />}
      >
        <SimpleShowLayout>
          <h3>Swap details</h3>
          <TextField source="id" />
          <TextField source="signature" label="Signature" />
          <TextField source="fee" />
          <TextField source="volume" label="Volume (USD)" />
          <TextField source="createdAt" label="Date" />
          <ReferenceField
            source="profileId"
            reference="profiles"
            label="Creator"
            link="show"
          >
            <TextField source="username" mr={2} />
            <TextField source="id" />
          </ReferenceField>
          <FunctionField
            label="Frontend source data"
            render={(record) =>
              record?.appData
                ? JSON.stringify(record?.appData, undefined, 2)
                : "--"
            }
          />
          <FunctionField
            label="Analytics backend source data"
            render={(record) =>
              record?.analyticsData
                ? JSON.stringify(record?.analyticsData, undefined, 2)
                : "--"
            }
          />

          <h4>Referrers</h4>
          <ReferrerField level={1} />
          <ReferrerField level={2} />
          <ReferrerField level={3} />
          <ReferrerField level={4} />
          <ReferrerField level={5} />
          <ReferrerField level={6} />
        </SimpleShowLayout>
      </Show>
    </>
  );
};
