import {
  DateTimeInput,
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";

export default () => (
  <Edit
    mutationMode="pessimistic"
    mutationOptions={{
      onSuccess: () => window.location.replace("/payoutWindows"),
    }}
  >
    <SimpleForm>
      <TextInput source="id" disabled />
      <DateTimeInput source="startsAt" />
      <DateTimeInput source="endsAt" />
    </SimpleForm>
  </Edit>
);
