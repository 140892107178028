import {
  Datagrid,
  ExportButton,
  List,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from "react-admin";

export default () => {
  const { record } = useShowController();

  return (
    <Show title={"Payout window details"}>
      <SimpleShowLayout>
        <TextField source="startsAt" />
        <TextField source="endsAt" />
        <List
          resource="payoutWindows/items"
          actions={false}
          filter={{ id: record?.id }}
          pagination={false}
        >
          <ExportButton />
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="id" reference="profiles">
              <TextField source="username" />
            </ReferenceField>
            <TextField source="referralsTotal" />
            <TextField source="referralsTotal" />
            <TextField source="referralsWindow" />
            <TextField source="revenueTotal" />
            <TextField source="revenueWindow" />
          </Datagrid>
        </List>
      </SimpleShowLayout>
    </Show>
  );
};
