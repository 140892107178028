import {
  Datagrid,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from "react-admin";

export default () => {
  const { record } = useShowController();

  return (
    <>
      <Show title={"Referral details"}>
        <SimpleShowLayout>
          <h3>Referral details</h3>
          <ReferenceField source="id" reference="profiles" label="Root" link="show">
            <TextField source="id" mr={2} />
            <TextField source="username" />
          </ReferenceField>
          <TextField
            source="totalFees"
            label="Total Fees of directly+indirectly invited users after applying split %s"
          />
          <TextField source="totalLevel1Invites" label="Count of L1 invites" />
          <TextField
            source="totalAllLevelInvites"
            label="Count of L1-L6 invites"
          />
          <h4>L1-L6 referrals</h4>
          <Datagrid
            resource="referrals"
            data={record.referrals}
            bulkActionButtons={false}
          >
            <TextField source="level" />
            <ReferenceField
              source="profileId"
              reference="profiles"
              label="Invited user"
	      link="show"
            >
              <TextField source="username" mr={2} />
	      <TextField source="id" />
            </ReferenceField>
            <TextField source="totalFees" />
          </Datagrid>
        </SimpleShowLayout>
      </Show>
    </>
  );
};
