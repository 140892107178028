import {
  ArrayInput,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import { ColorInput } from "react-admin-color-picker";
import { VideoField } from "../../components/VideoField";

export default () => (
  <Edit
    title={"Edit filter"}
    mutationMode="pessimistic"
    mutationOptions={{ onSuccess: () => window.location.replace("/filters") }}
  >
    <SimpleForm>
      <TextInput required source="name" />
      <NumberInput source="order" />
      <ImageInput
        source="iconUrl"
        accept={{ "image/*": [".svg"] }}
        label="Image"
        id="iconUrl"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="priceCursorIconUrl"
        accept={{ "image/*": [".svg"] }}
        label="Price Cursor Icon"
        id="priceCursorIconUrl"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <SelectInput
        source="kind"
        required
        defaultValue={"default"}
        choices={[
          { id: "default", name: "default" },
          { id: "gradient", name: "gradient" },
          { id: "mp4", name: "video" },
          { id: "photo", name: "photo"},
        ]}
      />
      <ArrayInput source="gradients" label="Colors">
        <SimpleFormIterator inline>
          <ColorInput source="color" label="Color" />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput
        source="chartKind"
        required
        choices={[{ id: "candlestick", name: "candlestick" }]}
        defaultValue={"candlestick"}
      />
      <NumberInput source="version" defaultValue={0} disabled />
      <ColorInput isRequired source="green" label="Positive candle color" />
      <ColorInput isRequired source="red" label="Negative candle color" />
      <ImageInput
        source="videoUrl"
        accept={{ "video/*": [".mp4"] }}
        label="Video"
        sx={{
          '& .RaFileInputPreview-removeButton': {
            zIndex: 1,
          },
        }}
      >
        <VideoField source="src" />
      </ImageInput>
      <NumberInput source="videoOpacity" defaultValue={1} min={0} max={1} />
    </SimpleForm>
  </Edit>
);
