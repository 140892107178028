import {
  Datagrid,
  DateField,
  EditButton,
  List,
} from "react-admin";

export default () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <DateField source="startsAt" />
      <DateField source="endsAt" />
      <EditButton />
    </Datagrid>
  </List>
);
