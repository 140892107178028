import {
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from "react-admin";

type ReferralListProps = Omit<ListProps, "children">;

export default (props: ReferralListProps) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <DateField source="createdAt" />
      <ReferenceField source="id" reference="profiles" label="Root">
        <TextField source="id" mr={2}/>
        <TextField source="username" />
      </ReferenceField>
    </Datagrid>
  </List>
);
