import {
  Create,
  DateInput,
  SimpleForm,
} from "react-admin";

export default () => (
  <Create redirect="/payoutWindows">
    <SimpleForm>
      <DateInput source="startsAt" />
      <DateInput source="endsAt" />
    </SimpleForm>
  </Create>
);
