import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Link,
  List,
  ListActions,
  ListProps,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import { ROUTES } from "../../utils/routes";

export const Action = () => {
  const record = useRecordContext();

  return (
    <>
      <EditButton />
      <DeleteWithConfirmButton
        title="Delete Invite Code"
        confirmContent="Are you sure you want to delete this invite code?"
        confirmColor="warning"
        confirmTitle={`Delete ${record?.code}`}
        mutationMode="pessimistic"
        mutationOptions={{
          onSuccess: () => {
            window.location.reload();
          },
        }}
      />
    </>
  );
};

type InviteCodesListProps = Omit<ListProps, "children">;

const ToolbarActions = ({ ownerId }: { ownerId: string }) => (
  <div
    style={{
      display: "flex",
      gap: "0.25rem",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Button
      style={{ marginTop: "0.25rem" }}
      variant="text"
      color="primary"
      component={Link}
      to={{
        pathname: ROUTES.INVITES_CREATE_MANY,
        search: `?ownerId=${ownerId}`,
      }}
      label="Create many"
      startIcon={<AddIcon />}
    />
    <ListActions />
  </div>
);

export default (props: InviteCodesListProps) => (
  <List
    title={"Invite codes"}
    actions={
      props.filter?.ownerId ? (
        <ToolbarActions ownerId={props.filter.ownerId} />
      ) : undefined
    }
    {...props}
  >
    <Datagrid bulkActionButtons={false}>
      <ReferenceField source="ownerId" reference="profiles" label="Owner">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="code" />
      <TextField source="uses" />
      <Action />
    </Datagrid>
  </List>
);
